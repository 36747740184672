import videoBg from './assets/videos/BgVidGlitch.mp4';
import './assets/fonts/Term/Term.ttf';

function App() {
  return (
    <div class="Background">
      <div class="Overlay"></div>
      <video src={videoBg} autoPlay loop muted></video>
      <div class="Content">
        <h1>CAPATI INDUSTRIES</h1>
        <h2>Find out.</h2>
      </div>
    </div>
  );
}

export default App;
